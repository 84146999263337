import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store'
let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function(location, resolve, reject) {
  if (resolve && reject) {
   
    originPush.call(this, location, resolve, reject);
  } else {
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
VueRouter.prototype.replace = function(location, resolve, reject) {
  if (resolve && reject) {
    originReplace.call(this, location, resolve, reject);
  } else {
    originReplace.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    meta:{
      title:'Log in - Repshipping'
    },
    component: () => import("../views/login/login.vue"),
  },
  {
    path: "/signup",
    name: "signup",
    meta:{
      title:'Sign up - Repshipping'
    },
    component: () => import("../views/login/signup.vue"),
  },
  {
    path: "/verifyEmail",
    name: "verifyEmail",
    meta:{
      title:'Verify Email - Repshipping'
    },
    component: () => import("../views/login/verifyEmail.vue"),
  },
  {
    path: "/forgetPassword",
    name: "forgetPassword",
    meta:{
      title:'Forget Password - Repshipping'
    },
    component: () => import("../views/login/forgetPassword.vue"),
  },
  {
    path: "/",
    redirect: "/forwarding",
    meta:{
      title:'Repshipping'
    },
    component: () => import("../views/HomeView.vue"),
    children:[
      {
        path: "/forwarding",
        name: "forwarding",
        meta:{
          title:'Forwarding - Repshipping'
        },
        component: () => import("../views/forward/index.vue"),
      },
      {
        path: "/orders",
        name: "order",
        meta:{
          title:'Orders - Repshipping'
        },
        component: () => import("../views/order/index.vue"),
      },
      {
        path: "/orderDetails/:id",
        name: "orderDetails",
        meta:{
          title:'Order Details - Repshipping'
        },
        component: () => import("../views/order/details.vue"),
      },
      {
        path: "/person",
        name: "person",
        meta:{
          title:'Person center - Repshipping'
        },
        component: () => import("../views/user/center.vue"),
        children:[
          {
            path: "info",
            name: "info",
            meta:{
              title:'My info - Repshipping'
            },
            component: () => import("../views/user/page/info"),
          },
          {
            path: "points",
            name: "points",
            meta:{
              title:'My points - Repshipping'
            },
            component: () => import("../views/user/page/points"),
          },
          {
            path: "coupon",
            name: "coupon",
            meta:{
              title:'My coupon - Repshipping'
            },
            component: () => import("../views/user/page/coupon"),
          },
          {
            path: "address",
            name: "address",
            meta:{
              title:'My address - Repshipping'
            },
            component: () => import("../views/user/page/address"),
          },
          {
            path: "password",
            name: "password",
            meta:{
              title:'My password - Repshipping'
            },
            component: () => import("../views/user/components/password_form"),
          }
        ]
      },
      {
        path: "/warehouse",
        name: "warehouse",
        meta:{
          title:'Warehouse - Repshipping'
        },
        component: () => import("../views/warehouse/index.vue"),
      },
      {
        path: "/submit",
        name: "waybill",
        meta:{
          title:'Waybill - Repshipping'
        },
        component: () => import("../views/warehouse/waybill.vue"),
      },
      {
        path: "/success",
        name: "orderSuccess",
        meta:{
          title:'Success - Repshipping'
        },
        component: () => import("../views/warehouse/success.vue"),
      },
      {
        path: "/payment",
        name: "payment",
        meta:{
          title:'Payment - Repshipping'
        },
        component: () => import("../views/warehouse/payment.vue"),
      },
      {
        path: "/track",
        name: "track",
        meta:{
          title:'My track - Repshipping'
        },
        component: () => import("../views/track/index.vue"),
      },
      {
        path: "/booking",
        name: "booking",
        redirect: "/booking/add",
        meta:{
          title:'Booking - Repshipping'
        },
        component: () => import("../views/booking/index.vue"),
        children:[
          {
            path: "add",
            name: "add",
            meta:{
              title:'Booking add - Repshipping'
            },
            component: () => import("../views/booking/page/add"),
          },
          {
            path: "list",
            name: "list",
            meta:{
              title:'Booking list - Repshipping'
            },
            component: () => import("../views/booking/page/list"),
          },
          {
            path: "arrived",
            name: "arrived",
            meta:{
              title:'Booking arrived - Repshipping'
            },
            component: () => import("../views/booking/page/arrived"),
          },
        ]
      },
      {
        path: "/help",
        name:'help',
        meta:{
          title:'Help center - Repshipping'
        },
        component: () => import("../views/help/index.vue"),
      },
      {
        path: "/message",
        name:'chat',
        meta:{
          title:'Message - Repshipping'
        },
        component: () => import("../views/chat/index.vue"),
      },
      {
        path: "/chatedit",
        name:'chatedit',
        meta:{
          title:'Chat edit - Repshipping'
        },
        component: () => import("../views/chat/components/edit.vue"),
      },
      {
        path: "/purchase",
        name: "purchase",
        redirect: "/purchase/search",
        meta:{
          title:'Purchase - Repshipping'
        },
        component: () => import("../views/purchase/index.vue"),
        children:[
          {
            path: "search",
            name: "search",
            meta:{
              title:'Purchase search - Repshipping'
            },
            component: () => import("../views/purchase/page/search"),
          },
          {
            path: "list",
            name: "purchaselist",
            meta:{
              title:'Purchase list - Repshipping'
            },
            component: () => import("../views/purchase/page/list"),
          },
          {
            path: "payment",
            name: "purchasepayment",
            meta:{
              title:'Purchase payment - Repshipping'
            },
            component: () => import("../views/purchase/page/payment"),
          },
          {
            path: "success",
            name: "purchaseSuccess",
            meta:{
              title:'Success - Repshipping'
            },
            component: () => import("../views/purchase/page/success"),
          },
          {
            path: "ordered",
            name: "ordered",
            meta:{
              title:'Purchase ordered - Repshipping'
            },
            component: () => import("../views/purchase/page/order"),
          },
          {
            path: "details/:id",
            name: "purchasedetails",
            meta:{
              title:'Order details - Repshipping'
            },
            component: () => import("../views/purchase/page/details"),
          },
          {
            path: "pending",
            name: "purchasepending ",
            meta:{
              title:'Pending - Repshipping'
            },
            component: () => import("../views/purchase/page/pending"),
          },
          {
            path: "purchased",
            name: "purchased",
            meta:{
              title:'Purchased - Repshipping'
            },
            component: () => import("../views/purchase/page/purchased"),
          },
          {
            path: "arrived",
            name: "purchasearrived",
            meta:{
              title:'Arrived - Repshipping'
            },
            component: () => import("../views/purchase/page/arrived"),
          },
          {
            path: "packSuccess",
            name: "packSuccess",
            meta:{
              title:'Success - Repshipping'
            },
            component: () => import("../views/purchase/page/packSuccess"),
          },
        ]
      }
    ]
  },
  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to,from,next)=>{
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
});

export default router;
