import request from '@/axios/request'

export function getUserList(params) {
  return request({
    url: '/customer/user/list',
    method: 'get',
    params
  })
}

export function editPassword(data) {
  return request({
    url: '/customer/user/edit_pass',
    method: 'post',
    data
  })
}

export function login(data) {
    return request({
      url: '/customer/login/verifylogin',
      method: 'post',
      data
    })
  }

  export function createAddress(data) {
    return request({
      url: '/customer/address/create',
      method: 'post',
      data
    })
  }

  export function editAddress(data) {
    return request({
      url: '/customer/address/edit',
      method: 'post',
      data
    })
  }

  export function delAddress(data) {
    return request({
      url: '/customer/address/del',
      method: 'post',
      data
    })
  }

  export function getAddressList(params) {
    return request({
      url: '/customer/address/list',
      method: 'get',
      params
    })
  }

  export function getCouponList(params) {
    return request({
      url: '/customer/coupon/list',
      method: 'get',
      params
    })
  }

  export function getExpList(params) {
    return request({
      url: '/customer/user/get_exp',
      method: 'get',
      params
    })
  }

  export function getRegister(data) {
    return request({
      url: '/customer/login/register',
      method: 'post',
      data
    })
  }

  export function verifyCode(data) {
    return request({
      url: '/customer/login/verifycode',
      method: 'post',
      data
    })
  }

  export function sendCode(params) {
    return request({
      url: '/customer/login/send',
      method: 'get',
      params
    })
  }

  export function withDraw(data) {
    return request({
      url: '/customer/order/draw',
      method: 'post',
      data
    })
  }

  export function resetPassword(data) {
    return request({
      url: '/customer/login/reset_pass',
      method: 'post',
      data
    })
  }

  export function payLink(data) {
    return request({
      url: '/customer/order/recharge',
      method: 'post',
      data
    })
  }

  export function payOrder(data) {
    return request({
      url: '/customer/order/capture',
      method: 'post',
      data
    })
  }

  export function uploadFile(data) {
    return request({
      url: '/customer/order/upload',
      method: 'post',
      data
    })
  }


  export function getMessageNum(params) {
    return request({
      url: '/customer/user/untreated',
      method: 'get',
      params
    })
  }

  export function uploadAvatars(data) {
    return request({
      url: '/common/upload',
      method: 'post',
      data
    })
  }

  export function editAvatars(data) {
    return request({
      url: '/customer/user/avatar',
      method: 'post',
      data
    })
  }