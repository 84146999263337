const TokenKey = 'vue_admin_template_token'

export function getToken() {
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}

export function getName() {
  return localStorage.getItem('name')
}

export function setName(name) {
  return localStorage.setItem('name',name)
}

export function getHeader() {
  return localStorage.getItem('header')
}

export function setheader(name) {
  return localStorage.setItem('header',name)
}

export function removeToken() {
  localStorage.clear();
  return localStorage.removeItem(TokenKey)
}