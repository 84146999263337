import Vue from "vue";
import Vuex from "vuex";
import getters from './getters';
import user from './modules/user'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    couponCount: 0,
    balance: 0
  },
  getters,
  mutations: {
    setBalance(state, count){
      state.balance = count;
    },
    setCoupon(state, count){
      state.couponCount = count;
    },
    addCoupon(state){
      state.couponCount++;
    }
  },
  actions: {
  },
  modules: {
    user
  },
});
