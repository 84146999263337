import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./style/tailwindcss.css";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'
import '@/validate/validate';
import LightTimeline from 'vue-light-timeline';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'

Vue.config.productionTip = false;

Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

Vue.use(ElementUI, { locale })

Vue.use(LightTimeline);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
